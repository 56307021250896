import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "./logo";
import LogoutLink from "./logoutLink";
import NavLink from "./navLink";

function DesktopHeader({ currentUser, flippers, subdomain, logoPath}) {
    const location = useLocation();
    const { t } = useTranslation();

    const active  = location.pathname.startsWith(`/users/${currentUser?.id}`) && !location.pathname.endsWith("/subscriptions");

    const renderLoggedInLinks = () => (
        <>
            {['admin', 'superadmin'].includes(currentUser.kind) && <li className="nav-item">
                <a href="/admin" className="nav-link">{t('admin.admin')}</a>
            </li>}
            <NavLink to="/events" text={t('events')} />
            <NavLink to={`/users/${currentUser.id}/subscriptions`} text={t('subscriptions')} />
            <NavLink to={`/users/${currentUser.id}`} text={t('profile')} active={active}/>
            {flippers.workshops_ui && <NavLink to="/workshops" text={t('workshops')} />}
            <li className="nav-item">
                <LogoutLink />
            </li>
        </>
    );

    const renderLoggedOutLinks = () => (
        <>
            <NavLink to="/sign_in" text={t('login')} active={location.pathname === '/'}/>
            {flippers.workshops_ui && <NavLink to="/workshops" text={t('workshops')} />}
        </>
    );

    if (location.pathname.startsWith('/admin')) {
        return null;
    } else {
        return (
            <nav className="navbar navbar-expand navbar-dark mb-2 bg-white d-none d-md-block shadow">
                <div className="container-fluid">
                    <Logo logoPath={logoPath} currentUser={currentUser} subdomain={subdomain}/>

                    <ul className="navbar-nav ms-auto">
                        {currentUser ? renderLoggedInLinks() : renderLoggedOutLinks()}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default DesktopHeader;
