import React from "react";

function ContainerFluid({noFlex, shadow, onClick, className, children, dontWrap, align = 'center'}) {
        return (
            <div className={`container-fluid rounded-3 align-items-${align} ${!noFlex && 'd-flex' } ${shadow && 'shadow-sm' } ${className}`}
                 role={onClick ? "button" : null}
                 onClick={onClick} >
                    {React.Children.map(children, (child) =>
                        child && (dontWrap ? React.cloneElement(child) : (
                            <div className="d-flex" key={child?.key}>
                                    {React.cloneElement(child)}
                            </div>)
                        )
                    )}
            </div>
        );
}

export default ContainerFluid;
