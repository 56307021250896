import React, {useEffect, useMemo, useRef, useState} from "react";
import Switch from "../switches/switch";
import ContainerFluid from "../containers/container_fluid";
import {duration, emptyFunction, performAjaxRequest} from "../../helpers";
import {useTranslation} from "react-i18next";
import TooltipWrapper from "../tooltip";
import TextSmall from "../textSmall";
import FullBadge from "../badges/fullBadge";

function GroupCard (props) {
    const [checked, setChecked] = useState(props.groupIds.includes(props.group.id))
    const switchRef = useRef()
    const { t } = useTranslation()

    useEffect(() => {
        setChecked(props.groupIds.includes(props.group.id))
    }, [props.groupIds, props.group, props.user]);

    const handleSwitchChange = (newChecked) => {
        setChecked(newChecked)
        switchRef.current.setState({checked: newChecked})
    }

    const existingSubscription = useMemo(() => {
        return props.user?.subscriptions?.some(subscription =>
            (subscription.active && subscription.group?.id === props.group.id) ||
            (checked && subscription.combined && subscription.groups.some(g => g.id === props.group.id))
        );
    }, [props.user?.subscriptions, props.group?.id, checked]);


    const handleClick = async (e) => {
        e.preventDefault();

        if (existingSubscription && checked) {
            const isConfirmed = window.confirm(t('confirm.exit_group'));
            if (!isConfirmed) return;
        }

        const newChecked = !checked
        handleSwitchChange(newChecked)

        if (checked) {
            await performAjaxRequest(
                'DELETE',
                `/group_users/${props.group.id}`,
                {user_id: props.user.id},
                { success: () => props.setGroupIds(prevGroupIds => prevGroupIds.filter(id => id !== props.group.id)),
                          error: () =>  handleSwitchChange(checked) }
            )
        } else {
            await performAjaxRequest(
                'POST',
                `/group_users`,
                {group_id: props.group.id, user_id: props.user.id},
                {success: () => {
                            props.setShowMessage(true)
                            props.setGroupIds(prevGroupIds => [...prevGroupIds, props.group.id]);
                        },
                        error: response => {
                            handleSwitchChange(checked)
                            props.setFlash({'alert': response.responseJSON});
                            props.setHideKeys(true);
                        }
                }
            )
        }
    }

    const fullGroup = useMemo(() => {
        return !checked &&
            ((props.user.gender === "male" && props.group.full_for_male) ||
                (props.user.gender === "female" && props.group.full_for_female))
    }, [props.user, props.group])

    return <ContainerFluid className={`justify-content-between bg-white my-2 px-3 py-2`}
                           shadow={true}
                           dontWrap={true}
                           onClick={fullGroup ? null : handleClick} >
        <div className="d-flex flex-column">
            <div>
                <span className='fs-5'>{props.group.name}</span>
                {existingSubscription && <TooltipWrapper text={t('existing_subscription')} icon="info-circle" />}
            </div>
            <TextSmall text={`${t('daysOfWeek.' + props.group.day_of_week)} ${duration(props.group)}`} />
            <TextSmall text={props.group?.address} />
            <TextSmall text={props.group.teachers_name} />
        </div>
        {fullGroup ?
            <FullBadge /> :
            <Switch ref={switchRef} style={{"transform": "scale(1.3)"}} checked={checked} handleChange={emptyFunction}/>
        }
    </ContainerFluid>
}

export default GroupCard;
