import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Submit from "./fields/submit";
import {performAjaxRequest} from "../../helpers";
import BasicForm from "./basicForm";
import Password from "./fields/password";
import DataPolicy from "./fields/dataPolicy";
import Preferences from "./fields/preferences";
import Back from "./fields/back";

function RegistrationSecondStep(props) {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        if (props.formData['confirm_token'] !== null && props.formData['confirm_token'] !== undefined) {
            await performAjaxRequest(
                "POST",
                `/users/${props.formData['confirm_token']}/confirm_email`,
                { user: props.formData },
                {props: props})
        } else {
            await performAjaxRequest(
                "POST",
                '/users.json',
                { user: props.formData },
                {props: props})
        }

        setIsSubmitting(false);
    }

    return <BasicForm onSubmit={onSubmit}>
        <Password autoFocus={true} value={props.formData?.password || "" } {...props} />
        <Preferences {...props} />
        <DataPolicy dataPolicyPage={2} {...props} />
        <Submit text={t('finish')} disabled={isSubmitting} />
        <Back previousPage={0} {...props} />
    </BasicForm>
}

export default RegistrationSecondStep;