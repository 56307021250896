import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import Logo from "./logo";
import LogoutLink from "./logoutLink";

function MobileHeader({ currentUser, subdomain, logoPath }) {
    const { t } = useTranslation();
    const location = useLocation();

    const pageTitle = () => {
        const path = location.pathname;
        const titleMapping = {
            '/events': 'events',
            '/users/new': 'registration',
            '/workshops': 'workshops'
        };

        if (titleMapping[path]) {
            return t(titleMapping[path]);
        } else if (path.startsWith('/workshops ') && path.endsWith('apply')) {
            return t('apply');
        } else if (path.startsWith('/users/')) {
            const parts = path.split('/');
            const dynamicPart = parts[parts.length - 1];

            switch (dynamicPart) {
                case 'notification_settings':
                    return t('notification_settings');
                case 'subscriptions':
                    return t('subscriptions');
                case 'groups':
                    return t('groups');
                case 'personal':
                    return t('personal');
                default:
                    return t('profile');
            }
        } else {
            return 'DancePass';
        }
    };

    if (!location.pathname.startsWith('/admin')) {
        return (
            <nav className="navbar navbar-dark mb-2 d-block d-md-none">
                <div className="container-fluid justify-content-between">
                    <Logo logoPath={logoPath} currentUser={currentUser} subdomain={subdomain}/>

                    <h1>{pageTitle()}</h1>

                    <div className="ms-1" style={{width: '60px'}}>
                        {currentUser && <LogoutLink /> }
                    </div>
                </div>
            </nav>
        );
    }
}

export default MobileHeader;