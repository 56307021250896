import React, {useEffect, useState} from "react";
import MultiStepForm from "../components/forms/multiStepForm";
import SubscriptionPaymentFirstStep from "../components/forms/payment/subscriptionPaymentFirstStep";
import {performAjaxRequest} from "../helpers";
import {useParams} from "react-router";
import SubscriptionPaymentSecondStep from "../components/forms/payment/subscriptionPaymentSecondStep";
import {useLocation} from "react-router-dom";

function EventPaymentPage(props) {
    const { id } = useParams();
    const location = useLocation();
    const initialPage = location.state?.page || 0;
    const defaultFormData = location.state?.formData;
    const pages = [SubscriptionPaymentFirstStep, SubscriptionPaymentSecondStep];
    const [event, setEvent] = useState(null);

    useEffect(() => {
        performAjaxRequest(
            'GET',
            `/events/${id}.json`,
            null,
            {success: (response) => {setEvent(response)}, props: props})
    }, []);

    return <MultiStepForm pages={pages} numSteps={pages.length} event={event} defaultFormData={defaultFormData} initialPage={initialPage} {...props}/>
}

export default EventPaymentPage;