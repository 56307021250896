import React, { useRef, useState } from "react";
import {FormCheck} from "react-bootstrap";
import ContainerFluid from "../containers/container_fluid";
import {useTranslation} from "react-i18next";
import TextPrimary from "../textPrimary";
import TextSmall from "../textSmall";


function wsEventCard(props) {
    const checkboxRef = useRef();
    const [checked, setChecked] = useState(props.checked || false);
    const { t } = useTranslation();

    function handleClick() {
        const newChecked = !checked;
        setChecked(newChecked)
        props.checkboxInputChange(props.id, newChecked);
    }

    return <ContainerFluid shadow={true} className="justify-content-between bg-body my-2 py-2" onClick={handleClick}>
        <div>
            <TextPrimary text={props.event.name} br={true}/>
            {!props.hideDate && <>
                <TextSmall text={t(props.event.level)} br={true}/>
                <TextSmall text={`${props.event.start_time} - ${props.event.end_time}`} br={true}/>
                <TextSmall text={props.event.teacher} br={true}/>
            </>}
        </div>

        {props.hideCheckbox ? <></> :
            <FormCheck>
                <FormCheck.Input ref={checkboxRef}
                                 className="mb-2 border border-secondary fs-5"
                                 type="checkbox"
                                 name="ws_event_ids"
                                 onChange={e => e.stopPropagation()}
                                 checked={checked}/>
            </FormCheck>
        }
    </ContainerFluid>
}

export default wsEventCard;
