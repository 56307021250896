import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import EventCard from "../components/cards/eventCard";
import {useLocation} from "react-router-dom";
import ContainerFluid from "../components/containers/container_fluid";
import TextSmall from "../components/textSmall";

function EventsPage(props) {
    const [events, setEvents] = useState(null);
    const [applications, setApplications] = useState(null);
    const { t } = useTranslation();
    const location = useLocation();

    const fetchData = async () => {
        const [applicationsResponse, eventsResponse] = await Promise.all([
            $.get('/applications.json'),
            $.get('/events.json')
        ]);
        setApplications(applicationsResponse);
        setEvents(eventsResponse);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            fetchData();
        }
    };

    useEffect(() => {
        fetchData();
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    useEffect(() => {
        fetchData();
    }, [location]);

    const groupedEvents = useMemo(() => {
        if (!events) return {};

        return events.reduce((groups, event) => {
            const date = event.day;
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(event);
            return groups;
        }, {});
    }, [events]);


    if (props.currentUser.groups.length === 0) {
        return <span dangerouslySetInnerHTML={{__html: t('no_groups_message', {userId: props.currentUser.id,}),}}/>
    } else if (events?.length > 0) {
        return <div style={{marginBottom: "90px"}}>
            <div className="my-4">
                <span dangerouslySetInnerHTML={{__html: t('cant_find_groups_message', {userId: props.currentUser.id}),}}/>
            </div>

            {Object.entries(groupedEvents)?.map(([day, events]) => {
                return events.map((event, index) => {
                        const application = applications?.find(application => application.event_id === event.id)

                        return <ContainerFluid key={event.id} dontWrap={true} align="start" className="my-2 ps-0">
                            <div className="col-2 col-md-1 mt-2 text-center">
                                {index === 0 && <>
                                    <TextSmall text={day.split(' ')[0]} noSecondary={true} br={true}/>
                                    <TextSmall text={day.split(' ')[1]} noSecondary={true}
                                               className="rounded-circle bg-white p-1"/>
                                </>}
                            </div>

                            <EventCard key={event.id}
                                       event={event}
                                       applicationId={application?.id}
                                       subscription={application?.subscription}
                                       payment={application?.payment}
                                       fetchData={fetchData}
                                       {...props} />
                        </ContainerFluid>
                })
            })}
        </div>
    } else if (events?.length === 0) {
        return <span> {t('no_events')} </span>
    } else {
        return <span> {t('loading')} </span>
    }
}

export default EventsPage;