import {Link} from "react-router-dom";
import BsIcon from "../bsIcon";
import React from "react";
import {useTranslation} from "react-i18next";
import TextSmall from "../textSmall";

const FooterItem = ({ to, icon, label, active }) => {
    const {t} = useTranslation();

    return <div className="d-flex flex-column text-center">
        <Link to={to}>
            <BsIcon icon={icon}
                    classNames={`fs-2 footer_item text-secondary ${active || location.pathname === to ? 'bg-body p-2 mb-6 rounded' : ''}`}/>
        </Link>
        <TextSmall text={label ? label : t(to.substring(1))}
                   className="footer_text footer_item text-secondary mt-1" />
    </div>
};

export default FooterItem;