import React from "react";
import BasicForm from "../basicForm";
import BsIcon from "../../bsIcon";
import {useTranslation} from "react-i18next";
import Form from "react-bootstrap/Form";
import {FormCheck} from "react-bootstrap";
import Submit from "../fields/submit";
import {performAjaxRequest} from "../../../helpers";
import {useNavigate} from "react-router-dom";
import TextPrimary from "../../textPrimary";

function SubscriptionPaymentFirstStep({event, ...props}) {
     const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async () => {
        if (props.formData.payment_method === 'online') {
             props.setPage(1)
         } else if (props.formData.payment_method === 'cash') {
             await performAjaxRequest(
                 'POST',
                 '/applications',
                 { event_id: event.id },
                 {success: () => {
                         navigate('/events');
                         props.setFlash({'notice': t('flash.notices.successful_application', {group: event.group.name})});
                         props.setHideKeys(true);
                     },
                     props: props})

         }
     }

     if (event) {
          return <BasicForm onSubmit={onSubmit}>
              <div className="d-flex flex-column mx-3 mb-4 mt-2 px-3 py-2 bg-body rounded">
                  <TextPrimary text={event.group.name} />
                  <span className='small text-secondary mt-1'>
                       <BsIcon classNames="me-1" icon={'calendar-event'}/>
                      {event.formated_time}
                   </span>
                  {event.group?.short_address && <span className='small text-secondary'>
                       <BsIcon classNames="me-1" icon={'geo-alt'}/>
                      {event.group?.short_address}
                   </span>}
                  <span className='small text-secondary'>
                       <BsIcon classNames="me-1" icon={'person-raised-hand'}/>
                      {event.group.teachers_name}
                   </span>
              </div>

              <Form.Group className="m-3">
                  <Form.Label className="mb-2">{t('payment_method.question')}</Form.Label>
                  <FormCheck className="mb-2" isInvalid={props.formData.payment_method === ''}>
                      <FormCheck.Input onChange={props.handleInputChange}
                                       checked={props.formData.payment_method === "online"}
                                       className="mb-2 border border-secondary" type="radio" id="online"
                                       name="payment_method" value="online" required/>
                      <FormCheck.Label className="text-black" htmlFor="online"
                                       style={{width: "100%"}}> {t('payment_method.online')} </FormCheck.Label>
                  </FormCheck>
                  <FormCheck className="mb-2" isInvalid={props.formData.payment_method === ''}>
                      <FormCheck.Input onChange={props.handleInputChange}
                                       checked={props.formData.payment_method === "cash"}
                                       className="mb-2 border border-secondary" type="radio" id="cash"
                                       name="payment_method" value="cash" required/>
                      <FormCheck.Label className="text-black" htmlFor="cash"
                                       style={{width: "100%"}}> {t('payment_method.cash')} </FormCheck.Label>
                      <Form.Control.Feedback type="invalid"> {t('errors.messages.blank')} </Form.Control.Feedback>
                  </FormCheck>
              </Form.Group>

              <Submit text={t('application')}/>
              <p className="mt-3 text-center">
                  <a className="fs-6" href="#" onClick={(e) => { e.preventDefault(); navigate('/events'); }}>{t('back')}</a>
              </p>
          </BasicForm>
     } else {
         return <p>{t('loading')}</p>
     }
}

export default SubscriptionPaymentFirstStep;